@charset "UTF-8";
/*!
 *  dotudash ~ music/homepage
 *
 *  @author   Михаил Драгункин <contact@md.land>
 *  @version  1.0.0
 */
/* @group homepage */
/*!
 *  Gallery component.
 *
 *  Originally by Alessio Atzeni, rewritten and extended by Михаил Драгункин
 *
 *  @author   Alessio Atzeni
 *  @author   Михаил Драгункин
 *
 *  @see      http://tympanus.net/codrops/2011/11/02/original-hover-effects-with-css3/
 *  @version  1.0.0
 */
/* @group gallery component */
.gallery {
  /**
   * Fight space between images
   */
  font-size: 0;
  vertical-align: top;
  text-align: center; }
  .gallery__item {
    position: relative;
    display: inline-block;
    overflow: hidden;
    width: 250px;
    height: 250px;
    margin: 0;
    cursor: default;
    text-align: center;
    vertical-align: top;
    text-shadow: 1px 1px 0 #000;
    font-size: 12px; }
    .gallery__item:hover .gallery__image {
      transition-delay: 0s; }
    .gallery__item:hover .gallery__content {
      transition-delay: 0s;
      opacity: 1; }
    .gallery__item:hover .gallery__header {
      transition-delay: .1s;
      transform: scale(1);
      opacity: 1; }
    .gallery__item:hover .gallery__description {
      transition-delay: .2s;
      transform: scale(1);
      opacity: 1; }
    .gallery__item:hover .gallery__link {
      transition-delay: .3s;
      transform: translateY(0px);
      opacity: 1; }
  .gallery__content {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 250px;
    height: 250px;
    transition: all .3s ease-in .4s;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.5); }
  .gallery__description {
    position: relative;
    padding: 10px 20px 20px;
    transition: all .3s ease-in-out .2s;
    transform: scale(10);
    text-align: center;
    opacity: 0;
    color: #fff; }
  .gallery__header {
    position: relative;
    margin: 20px 20px 0 20px;
    padding: 10px;
    transition: all .3s ease-in-out .1s;
    transform: scale(10);
    text-align: center;
    text-transform: uppercase;
    opacity: 0;
    color: #fff;
    background: transparent; }
  .gallery__link {
    display: inline-block;
    padding: 7px 14px;
    transition: all .3s ease-in-out .1s;
    transform: translateY(100px);
    text-decoration: none;
    text-transform: uppercase;
    opacity: 0;
    color: #000;
    background: #fff;
    box-shadow: 0 0 1px #fff;
    text-shadow: none; }
    .gallery__link:hover {
      box-shadow: 0 0 5px #fff; }
  .gallery__image {
    position: relative;
    display: block;
    width: 100%;
    transition: all .4s ease-in-out .5s; }

/* @end */
/* @end */
